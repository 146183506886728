import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import ptLocale from '@fullcalendar/core/locales/pt-br';

// Services
import api from '../../../services/api';

// Components 
import HeaderRestrita from '../../../components/HeaderRestrita';
import Footer from '../../../components/Footer';

// Styles
import { Container, Outros } from './styles';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';

export default function Agenda() {

  // States
  const [eventos, setEventos] = useState(null);
  
  // useEffect para retornar os eventos
  useEffect(() => {
  
    // Função com a requisição assincrona
    const getEventos = async () => {

      // Faz a requisição com o slug
      const { data } = await api.get(`/agenda`);
      
      // Seta o estado
      setEventos(data.eventos);
    };

    // Chama a função
    getEventos();
  }, []);

  return (
    <>
      <Helmet>
        <title>Consipa | Agenda dos consultores</title>
      </Helmet>

      <HeaderRestrita />

      <main>
        <Container>
          <div className="wrapper content">
            <div className="titulozinho">
              <img height="33" src={require('../../../assets/img/arearestrita/Icone_Agenda.svg')} />
              <h2>Agenda dos consultores</h2>
            </div>

            <div className="calendario">
              <FullCalendar
                defaultView="dayGridMonth"
                locale={ptLocale} 
                header={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                }}
                plugins={[ dayGridPlugin, timeGridPlugin ]} 
                events={ eventos }
              />
            </div>
                
          </div>

          <Outros className="wrapper">
            <h3>Consulte outros históricos</h3>
            <ul>
              {/* <a href="/area-restrita/area-restrita/agenda-dos-consultores">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Agenda.svg')} />
                  <p>Agenda dos consultores</p>
                </li>
              </a> */}

              <a href="/area-restrita/historico-de-pagamentos">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Pagamentos.svg')} />
                  <p>Histórico de pagamentos</p>
                </li>
              </a>

              <a href="/area-restrita/historico-de-consultorias">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Visitas.svg')} />
                  <p>Histórico de consultorias</p>
                </li>
              </a>

              <a href="">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Remote.svg')} />
                  <p>Consultoria remota</p>
                </li>
              </a>
            </ul>
          </Outros>
        </Container>
      </main>

      <Footer />
    </>
  );
}
