import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Radio } from 'antd';

// Components 
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

// Services
import api from '../../../services/api';
import history from '../../../services/history';

// Styles
import { Container, Form } from './styles';

export default function Login() {

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [erro, setErro] = useState('');
  const [tipo,setTipo] = useState(null);

  // Radio button
  function onChange(e) {
    console.log('radio checked', e.target.value);
    setTipo(e.target.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    // Objeto de dados do form
    const dadosLogin = new FormData();
    dadosLogin.append('email', email);
    dadosLogin.append('senha', senha);
    dadosLogin.append('tipo', tipo);

    try {
      await api.post('/login', dadosLogin).then(response => {
        localStorage.setItem('id', response.data.id);
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('senha', response.data.senha);
        localStorage.setItem('nome', response.data.nome);
        localStorage.setItem('tipo', response.data.tipo);
        history.push('/area-restrita');
      });
    } catch (err) {
      localStorage.clear();
      setErro('Login e/ou senha inválidos. Por favor, tente novamente!');
      console.log(err);
    }
    setLoading(false);
    return true;
  }

  return (
    <>
      <Helmet>
        <title>Consipa | Login</title>
      </Helmet>

      <Header />

      <main className="fixed">
        <Container>
          <h2>Faça seu login</h2>

          <div className="wrapper">
            <img src={require('../../../assets/img/arearestrita/login.jpg')} />
            <Form
              action="/area-restrita"
              onSubmit={handleSubmit}
              method="post"
              encType="multipart/form-data"
            > 
              <label className="label">Acesso:</label>
              <Radio.Group onChange={onChange} value={tipo}>
                <Radio value="Consultor">Consultor</Radio>
                <Radio value="Produtor">Produtor</Radio>
              </Radio.Group>
              <label className="label">Email:</label>
              <input
                type="email"
                placeholder="Digite seu email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <label className="label">Senha:</label>
              <input
                type="password"
                placeholder="Digite sua senha"
                value={senha}
                onChange={e => setSenha(e.target.value)}
              />
              {erro && <p className="error">{erro}</p>}

              <button>Entrar</button>
            </Form>
          </div>
          
        </Container>
      </main>

      <Footer />
    </>
  );
}
