import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

// Services
import api from '../../../services/api';

// Components 
import HeaderRestrita from '../../../components/HeaderRestrita';
import Footer from '../../../components/Footer';

// Styles
import { Container, Outros } from './styles';

export default function HistoricoPagamentos() {

  // States
  const [dados, setDados] = useState(null);

  // useEffect
  useEffect(() => {
    
    // Função com a requisição assincrona
    const getDados = async () => {
      const { data } = await api.get('/consultoria-remota');

      // Setar no estado
      setDados(data);
    };

    // Chama a função
    getDados();
  }, []);

  return (
    <>
      <Helmet>
        <title>Consipa | Consultoria remota</title>
      </Helmet>

      <HeaderRestrita />

      <main>
        <Container>
          <div className="wrapper content">
            <div className="titulozinho">
              <img height="33" src={require('../../../assets/img/arearestrita/Icone_Remote.svg')} />
              <h2>OnSipa (Consultoria remota)</h2>
            </div>

            <div className="tabela">
              <div className="topo">
                <h3>Fale com um de nossos consultores via WhatsApp</h3>
              </div>
              <div className="corpo">

                {!!dados && dados.consultoria_remota.map( linha =>
                  
                  <div key={linha.consultor.ID} className="linha">
                    <div>
                      <p>{linha.consultor.post_title}</p>
                    </div>
                    <div className="comprovante">
                      <a href={`https://api.whatsapp.com/send?phone=55${linha.celular}&text=Ol%C3%A1%2C%20gostaria%20de%20falar%20sobre%20`} target="__blank">
                        Falar com consultor
                      </a>
                    </div>
                  </div>
                )}
              </div>{/* ./ corpo */}
            </div>
          </div>

          <Outros className="wrapper">
            <h3>Consulte outros históricos</h3>
            <ul>
              <a href="/area-restrita/agenda-dos-consultores">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Agenda.svg')} />
                  <p>Agenda dos consultores</p>
                </li>
              </a>

              <a href="/area-restrita/historico-de-pagamentos">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Pagamentos.svg')} />
                  <p>Histórico de pagamentos</p>
                </li>
              </a>

              <a href="/area-restrita/historico-de-consultorias">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Visitas.svg')} />
                  <p>Histórico de consultorias</p>
                </li>
              </a>
            </ul>
          </Outros>
        </Container>
      </main>

      <Footer />
    </>
  );
}
