import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import { Table, Modal } from 'antd';

// Services
import api from '../../../services/api';

// Components 
import HeaderRestrita from '../../../components/HeaderRestrita';
import Footer from '../../../components/Footer';

// Icons
import { FaEye } from 'react-icons/fa';
import { MdFileDownload } from 'react-icons/md';

// Styles
import { Container, Outros } from './styles';
import 'antd/lib/table/style/css';
import 'antd/lib/modal/style/css';

export default function HistoricoVisitas() {

  // States
  const [selectedModal, setSelectedModal] = useState({
    modalisopen: false,
    selectmodal: '',
  });
  const [visitas, setVisitas] = useState(null);

  // Variáveis localStorage
  const id = localStorage.getItem('id');
  const tipo = localStorage.getItem('tipo');

  // Colunas da tabela
  const colunas = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      width: '15%',
    },
    {
      title: 'Data',
      dataIndex: 'data',
      width: '20%',
    },
    {
      title: 'Consultor',
      dataIndex: 'consultor',
      width: '25%',
    },
    {
      title: 'Documento',
      dataIndex: 'documento',
      key: 'documento',
      width: '15%',
      render: (text, record) => (
        <span>
          <a href={record.documento} target="_blank" rel="noopener noreferrer">
            Baixar <MdFileDownload />
          </a>
        </span>
      ),
    },
    {
      title: 'Ações',
      key: 'acoes',
      width: '25%',
      render: (text, record) => (
        <>
          <button
            type="button"
            id="visualizar"
            onClick={() =>
              setSelectedModal({
                modalisopen: true,
                selectmodal: record.key,
              })
            }
          >
            Visualizar detalhes <FaEye />
          </button>
        </>
      ),
    },
  ];

  // useEffect
  useEffect( () => {

    async function getVisitas() {
      await api.get(`/visitas?tipo=${tipo}&id=${id}`).then(response => {
        setVisitas(response.data);

        console.log( response.data );
      });
    }
    getVisitas(); 
  }, []);
  
  const data =
  !!visitas && visitas.map(dado => ({
    key: dado.ID,
    codigo: dado.ID,
    data: dado.data,
    consultor: dado.consultor.data.display_name,
    documento: dado.documento
  }));


  return (
    <>
      <Helmet>
        <title>Consipa | Histórico de consultorias</title>
      </Helmet>

      <HeaderRestrita />

      <main>
        <Container>
          <div className="wrapper content">
            <div className="titulozinho">
              <img height="33" src={require('../../../assets/img/arearestrita/Icone_Visitas.svg')} />
              <h2>Histórico de consultorias <small>Total: {!!visitas && visitas.length}</small></h2>
            </div>

            <Table
            bordered={false}
            columns={colunas}
            dataSource={data} />
          </div>

          <Outros className="wrapper">
            <h3>Consulte outros históricos</h3>
            <ul>
              <a href="/area-restrita/agenda-dos-consultores">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Agenda.svg')} />
                  <p>Agenda dos consultores</p>
                </li>
              </a>

              <a href="/area-restrita/historico-de-pagamentos">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Pagamentos.svg')} />
                  <p>Histórico de pagamentos</p>
                </li>
              </a>

              {/* <a href="/area-restrita/historico-de-consultorias">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Visitas.svg')} />
                  <p>Histórico de consultorias</p>
                </li>
              </a> */}

              <a href="/area-restrita/consultoria-remota">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Remote.svg')} />
                  <p>consultoria remota</p>
                </li>
              </a>
            </ul>
          </Outros>
        </Container>
      </main>

      {!!visitas && visitas.map(dado => {
        if (selectedModal.selectmodal === dado.ID) {
            return (
              <Modal
                title={ReactHtmlParser(dado.titulo)}
                visible={selectedModal.modalisopen}
                footer={false}
                onCancel={() => setSelectedModal({ modalisopen: false })}
                key={dado.ID}
              >
                <div>
                  <p>
                    <span>DATA: </span>
                    {dado.data}
                  </p>
                  <p>
                    <span>CONSULTOR: </span>
                    {dado.consultor.data.display_name}
                  </p>
                </div>
                <span>DESCRIÇÃO: </span>
                {ReactHtmlParser(dado.descricao)}
              </Modal>
            );
          }
        })}
      <Footer />
    </>
  );
}
