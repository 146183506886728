import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Components
import PrivateRoute from '../components/PrivateRoute';

// Pages
// Amém
import Home from '../pages/Home';
import Consultoria from '../pages/Consultoria';
import Post from '../pages/Blog/Post';
import Login from '../pages/AreaRestrita/Login';
import AreaRestrita from '../pages/AreaRestrita';
import HistoricoPagamentos from '../pages/AreaRestrita/HistoricoPagamentos';
import HistoricoVisitas from '../pages/AreaRestrita/HistoricoVisitas';
import Agenda from '../pages/AreaRestrita/Agenda';
import ConsultoriaRemota from '../pages/AreaRestrita/ConsultoriaRemota';

export default function Routes() {
  return (
    <Switch>
       {/* rotas públicas */}
      <Route path="/" exact component={Home} strict={false} />
      <Route path="/consultoria-online-gratis" exact component={Consultoria} />
      <Route path="/blog/:slug" component={Post} />
      
      {/* rotas privadas */}
      <PrivateRoute path="/login" component={Login} />
      <PrivateRoute path="/area-restrita" exact component={AreaRestrita} />
      <PrivateRoute path="/area-restrita/consultoria-remota" exact component={ConsultoriaRemota} />
      <PrivateRoute path="/area-restrita/historico-de-pagamentos" exact component={HistoricoPagamentos} />
      <PrivateRoute path="/area-restrita/historico-de-consultorias" exact component={HistoricoVisitas} />
      <PrivateRoute path="/area-restrita/agenda-dos-consultores" exact component={Agenda} />
    </Switch>
  );
}
