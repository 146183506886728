import styled, { keyframes } from 'styled-components';

const heartBeat = keyframes`
  0%, 100%, 40%, 60% {
      transform: scale(1);
  }
  20%, 80% {
      transform: scale(1.3);
  }
`;

// Rodape
export const Rodape = styled.footer`
  background: #0A4D32;
  z-index: 1;
  position: relative;
  padding-top: 60px;

  @media only screen and (max-width : 480px) {
    padding-top: 0;
    margin-top: 0;
  }

  .infos {
    width: 670px;
    margin: 0 auto 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width : 480px) {
      margin: 0 auto;
      width: 100%;
      padding: 65px 0 40px 0;
      flex-direction: column;
    }

    .divisor {
      display: block;
      background: rgba(255,255,255,.5);
      width: 1px;
      height: 240px;

      @media only screen and (max-width : 480px) {
        width: 80%;
        height: 1px;
        margin: 45px 0 35px 0;
      }
    }

    div {
      width: 265px;
      color: #fff;

      h3 {
        font-size: 31px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 5px;

        @media only screen and (max-width : 480px) {
          font-size: 28px;
        }
      }
      > p {
        font-size: 18px;
      }
      .social {
        display: flex;

        a {
          height: 40px;
          width: 40px;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }

    img {
      height: 160px;

      @media only screen and (max-width : 480px) {
        height: 110px;
      }
    }
  }

  .creditos {
    width: 600px;
    height: 95px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width : 480px) {
      width: 90%;
      height: auto;
      flex-direction: column;
    }

    p {
      margin: 0;
      color: #fff;
      font-weight: bold;
      font-size: 14px;

      @media only screen and (max-width : 480px) {
        font-size: 13px;
        padding-bottom: 15px;

        &:last-of-type {
          padding-bottom: 20px;
        }
      }

      img {
        display: inline-block;
        margin: 0 4px;
        animation-name: ${heartBeat};
        animation-iteration-count: infinite;
        animation-duration: 2s;

      }

      a {
        color: #fff;
      }
    }
  }
`;
