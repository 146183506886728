import React from 'react';

// Services
import history from '../../services/history';

// Styles
import { Container } from './styles';

export default function HeaderRestrita() {
  
  // Limpar a sessão
  function handleLogout() {
    localStorage.clear();
    history.push('/');
  }

  return (
    <>  
      <Container>
        <div className="wrapper">
          <a href="/">
            <h1>
              <img src={require('../../assets/img/marca_consipa.svg')} height="85" />
            </h1>
          </a>

          <a href="/area-restrita">
            <p>Área restrita Consipa</p>
          </a>

          <button type="button" onClick={() => handleLogout()}>
            <span>X</span><strong>Sair</strong> e voltar para a home
          </button>
        </div>
      </Container>
    </>
  );
}
