import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('UA-158524565-1');
  console.log('GA_INITIALIZED');
};

export const logPageView = location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  console.log('GA_CHANGE_ROUTE: ', location.pathname);
};
