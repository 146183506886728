import styled from 'styled-components';

// Container
export const Container = styled.section`
  position: relative;
  padding-bottom: 100px;
  padding-top: 70px;
  background: #ffd89d;

  .content {
    width: 100%;
    background: #fff;
    border-radius: 25px;
    box-shadow: 5px 10px 30px 1px rgba(0,0,0,0.055);
    padding: 45px 50px;

    .titulozinho {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #f2f3f5;

      h2 {
        color: #DE6D33;
        font-weight: bold;
        margin: 8px 0 0 15px;
        font-size: 16px;
      }
    }
  }
  .calendario {
    padding: 30px 20px 20px 20px;
  }

  .fc-event, .fc-event-dot {
    background: #2d3e50;
    border-color: #2d3e50;
  }
`;

// Outros
export const Outros = styled.section`
  padding-top: 90px;

  h3 {
    color: #DE6D33;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    li {
      list-style: none;
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 35px 30px;
      height: auto;
      width: 100%;
      transition: all ease .4s;
      background: #fff;

      p {
        color: #DE6D33;
        font-weight: bold;
        margin: 0 0 0 25px;
      }
    }
    li:hover {
      background: #fef6e9
    }
  }
`;