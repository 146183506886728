/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-alert */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';

import history from '../../services/history';
import api from '../../services/api';

export default function PrivateRoute({ component: Component, ...rest }) {
  // Objeto de dados do form
  const dadosLogin = new FormData();
  dadosLogin.append('email', localStorage.getItem('email'));
  dadosLogin.append('senha', localStorage.getItem('senha'));
  dadosLogin.append('tipo', localStorage.getItem('tipo'));

  useEffect(() => {
    async function checkLogin() {
      try {
        await api.post('/login', dadosLogin);
      } catch (err) {
        history.push('/login');
        return false;
      }
      return true;
    }
    checkLogin();
  }, []);

  return <Route {...rest} render={props => <Component {...props} />} />;
}
