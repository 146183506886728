import React from 'react';
import { Helmet } from 'react-helmet';
import { Radio, Checkbox } from 'antd';

// Components 
import Header from '../../components/Header';
import Footer from '../../components/Footer';

// Styles
import { Container, Form } from './styles';
import 'antd/lib/radio/style/index.css';
import 'antd/lib/checkbox/style/index.css';

export default function Consultoria() {

  const opcoesAtividade = [
    { label: 'Leite', value: 'Leite' },
    { label: 'Corte', value: 'Corte' },
  ];


  return (
    <>
      <Helmet>
        <title>Consipa | Avaliação Online Grátis</title>
      </Helmet>

      <Header />

      <main>
        <Container>
          <p>Preencha o formulário e receba uma</p>
          <h2>avaliação online grátis</h2>

          <Form>
            <label className="label">Selecione seu perfil:</label>
            <div className="bloco">
              <Radio.Group name="radiogroup" defaultValue={0}>
                <Radio value={1}>Dono</Radio>
                <Radio value={2}>Técnico</Radio>
              </Radio.Group>
            </div>

            <label className="label">Selecione o sistema:</label>
            <div className="bloco">
              <Radio.Group name="radiogroup" defaultValue={0}>
                <Radio value={1}>Pastejo</Radio>
                <Radio value={2}>Confinamento</Radio>
              </Radio.Group>
            </div>

            <label className="label">Tamanho da sua propriedade:</label>
            <div className="bloco tamanho">
              <input type="text" /><span>ha</span>
            </div>

            <label className="label">Número de animais:</label>
            <div className="bloco tamanho">
              <input type="text" />
            </div>

            <label className="label">Tipo de atividade:</label>
            <div className="bloco atividade">
              <Checkbox.Group options={opcoesAtividade} defaultValue={['']} />
              <label htmlFor="outros" className="outros">Outros:</label><input id="outros" type="text" />
            </div>

            <label className="label">Nome:*</label>
            <input></input>

            <label className="label">Email:</label>
            <input type="email" />

            <label className="label">Telefone:*</label>
            <input />

            <div className="localizacao">
              <div>
                <label className="label">Cidade:</label>
                <input />
              </div>

              <div>
                <label className="label">Estado:</label>
                <input />
              </div>
            </div>

            <label className="label">Possui alguma observação?</label>
            <textarea rows="5" />

            <button>Enviar</button>
          </Form>
        </Container>
      </main>

      <Footer />
    </>
  );
}
