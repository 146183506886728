import styled from 'styled-components';

import IconeDepoimentos from '../../assets/img/depoimentos_icone.jpg';
import TopoBlog from '../../assets/img/blog_topo.jpg';
import TopoServicos from '../../assets/img/servicos_topo.jpg';
import TopoEquipe from '../../assets/img/equipe_topo.jpg';
import Raking_bg from '../../assets/img/raking_bg.jpg';

import ArrowPrev from '../../assets/img/arrow_prev.svg';
import ArrowNext from '../../assets/img/arrow_next.svg';

// Banner
export const Banner = styled.section`
  position: relative;
  height: 620px;

  .slide {
    height: 620px;
    position: relative;
    background: #0A4D32;

    @media only screen and (max-width : 480px) {
      height: 590px;
    }
  }

  picture {
    width: 100%;
    position: relative;
    z-index: 8;
    background: #043822;

    img {
      width: 100%;
      height: 620px;
      object-fit: cover;
      opacity: .65;

      @media only screen and (max-width : 480px) {
        height: 590px;
      }
    }
  }

  .content {
    position: absolute;
    z-index: 9;
    width: 750px;
    margin: 250px 0 0 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    @media only screen and (max-width : 480px) {
      width: 75%;
      margin: 160px 0 0 0;
    }

    h3 {
      color: #fff;
      font-size: 36px;
      font-weight: 900;
      line-height: 40px;

      @media only screen and (max-width : 480px) {
        font-size: 30px;
        line-height: 36px;
      }
    }

    p {
      color: #fff;
      font-size: 20px;

      @media only screen and (max-width : 480px) {
        font-size: 18px;
      }
    }

    button {
      margin-top: 70px;
      cursor: pointer;
      border: none;
      border-radius: 100px;
      color: #fff;
      background: #DE6D32;
      font-size: 12px;
      font-weight: bold;
      padding: 13px 17.5px;
      text-transform: uppercase;
      transition: all ease-in .45s;

      &:hover {
        background: #a03a04;
      }

      @media only screen and (max-width : 480px) {
        margin-top: 50px;
      }
    }
  }

  .slick-next {
    right: 17%;
    z-index: 91;
    transform: scale(.5);
    transition: all ease-in-out .3s;
    top: 41%;

    @media only screen and (max-width : 480px) {
      right: 2%;
    }
  }
  .slick-prev {
    left: 15%;
    z-index: 91;
    transform: scale(.5);
    transition: all ease-in-out .3s;
    top: 41%;

    @media only screen and (max-width : 480px) {
      left: 1%;
    }
  }
  .slick-next:hover, .slick-prev:hover {
    transform: scale(.6);
  }
  .slick-prev:before, .slick-next:before {
    opacity: .8;
  }
  .slick-next:before {
    content: url(${ArrowNext});
    transition: all ease-in-out .3s;
  }
  .slick-prev:before {
    content: url(${ArrowPrev});
    transition: all ease-in-out .3s;
  }
`;

// BemVindo
export const BemVindo = styled.section`
  padding: 120px 0;
  text-align: center;

  @media only screen and (max-width : 480px) {
    padding: 80px 10%;   
  }
  
  h2 {
    font-weight: 900;
    font-size: 38px;
    color: #00844E;
    text-transform: uppercase;
    margin: 0;

    @media only screen and (max-width : 480px) {
      font-size: 30px;
    }
  }
`;

// Sobre
export const Sobre = styled.section`
  background: #FFFDF2;
  padding-bottom: 180px;

  @media only screen and (max-width : 480px) {
    padding-bottom: 90px;
  }

  .quemSomos {
    display: grid;
    grid-template-columns: 55% 45%;
    padding: 130px 0;

    @media only screen and (max-width : 480px) {
      grid-template-columns: 100%;
      padding: 90px 0;
    }

    > div {
      padding-right: 60px;

      @media only screen and (max-width : 480px) {
        padding-right: 0;
      }
    }

    h3 {
      font-size: 24px;
      color: #00844E;
      text-transform: uppercase;
      margin: 30px 0 40px 30px;
      font-weight: bold;
      line-height: 28px;

      @media only screen and (max-width : 480px) {
        font-size: 20px;
        line-height: 24px;
        margin: 30px 0 40px 0;
      }
    }

    hr {
      border: 1px solid #00844E;
      margin-left: 30px;
      margin-top: 15px;

      @media only screen and (max-width : 480px) {
        margin-left: 0;
      }
    }

    p {
      color: #a8a8a8;
      font-size: 16px;
      line-height: 26px;
      margin-left: 30px;
      text-align: justify;

      @media only screen and (max-width : 480px) {
        margin-left: 0;
        font-size: 14px;
      }
    }

    .info {
      background: #fff;
      width: 100%;
      padding: 26px 0;
      box-shadow: 10px 13px 26px 1px rgba(0,0,0,.05);
      border-left: 15px solid #00844E;
      display: flex;
      padding-left: 32px;
      margin-top: 55px;
      transition: all ease-in .4s;

      @media only screen and (max-width : 480px) {
        margin: 55px 0;
      }

      h4 {
        font-size: 20px;
        color: #00844E;
        text-transform: uppercase;
        margin: 0 0 0 20px;
        line-height: 22px;
      }
      strong {
        color: #00844E;
        font-size: 20px;
        margin: 0 0 0 20px;
        
        @media only screen and (max-width : 480px) {
          font-size: 18px;
        }
      }
    }
    .info:hover {
      background: #00844E;

      h4, strong, svg {
        color: #fff;
        fill: #fff;
      }
    }

    img {
      height: 660px;

      @media only screen and (max-width : 480px) {
        height: 370px;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .oqueSao {
    padding: 130px 0 0 0;

    @media only screen and (max-width : 480px) {
      padding: 90px 0 20px 0;
    }
  }
  .oqueSao, .importancia {
    img {
      height: 300px;
      width: 100%;
      
      @media only screen and (max-width : 480px) {
        object-fit: cover;
      }
    }

    h2 {
      text-align: center;
      margin: 70px 0 60px 0;

      @media only screen and (max-width : 480px) {
        margin-bottom: 30px;
      }
    }

    div {
      width: 660px;
      margin: 0 auto;

      @media only screen and (max-width : 480px) {
        width: 100%;
      }
    }

    p {
      color: #a8a8a8;
      font-size: 16px;
      line-height: 28px;
      margin-left: 30px;
      font-weight: 500;
      text-align: justify;
      text-align-last: left;
      margin-top: 0;

      @media only screen and (max-width : 480px) {
        margin-left: 0;
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
`;

// Servicos
export const Servicos = styled.section`
  padding-bottom: 180px;

  @media only screen and (max-width : 480px) {
    padding-bottom: 90px;    
  }

  .topo {
    background: url(${TopoServicos});
    background-position: center;
    background-size: cover;
    height: 530px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 55px;

    @media only screen and (max-width : 480px) {
      height: 400px;
      padding-bottom: 30px;
    }

    h2 {
      color: #fff;
    }

    p {
      color: #fff;
      font-size: 18px;
      width: 390px;

      @media only screen and (max-width : 480px) {
        width: 85%;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 85px;
    padding: 110px 0 165px 0;

    @media only screen and (max-width : 480px) {
      grid-template-columns: 100%;
      padding: 50px 0 90px 0;
      grid-gap: 50px;
    }

    div {
      background: #fff;
      border: 1px solid #def1de;
      padding: 50px 35px 35px 35px;
      border-radius: 20px;

      @media only screen and (max-width : 480px) {
        padding: 40px 30px 30px 30px;
      }

      h3 {
        font-size: 24px;
        color: #00844E;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 15px;

        @media only screen and (max-width : 480px) {
          font-size: 20px;
        }
      }

      p {
        color: #a8a8a8;
        font-size: 16px;
        line-height: 26px;
        font-weight: 500; 

        @media only screen and (max-width : 480px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
`;

// Equipe
export const Equipe = styled.section`
  padding-bottom: 170px;
  
  @media only screen and (max-width : 480px) {
    padding-bottom: 90px;
  }

  .topo {
    background: url(${TopoEquipe});
    background-position: center;
    background-size: cover;
    height: 375px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 55px;

    @media only screen and (max-width : 480px) {
      height: 400px;
      padding-bottom: 30px;
    }

    h2 {
      color: #fff;
    }

    p {
      color: #fff;
      font-size: 18px;
      width: 390px;

      @media only screen and (max-width : 480px) {
        width: 85%;
      }
    }
  }

  .grid  {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    padding: 105px 0 165px 0;

    @media only screen and (max-width : 480px) {
      grid-template-columns: repeat(2, 50%);
      grid-gap: 0;
      padding: 50px 0 90px 0;
    }

    .box {
      picture {

        img {
          width: 100%;
          height: 300px;
          object-fit: cover;

          @media only screen and (max-width : 480px) {
            height: 200px;
          }
        }
      }
      
      div {
        border-right: 1px solid #F0F0F0;
        border-bottom: 1px solid #F0F0F0;
        padding: 40px 35px 35px 35px;
        height: 380px;

        @media only screen and (max-width : 480px) {
          padding: 20px 15px 15px 15px;
          height: 150px;
        }

        h3 {
          font-size: 20px;
          color: #00844E;
          text-transform: uppercase;
          font-weight: bold;
          margin-bottom: 5px;
          line-height: 22px;
          height: 45px;

          @media only screen and (max-width : 480px) {
            font-size: 18px;
            line-height: 20px;
            /* height: 60px; */
            height: auto;
          }
        }

        h4 {
          color: #a8a8a8;
          font-size: 16px;
          font-weight: bold;

          @media only screen and (max-width : 480px) {
            font-size: 15px;
            line-height: 18px;
          }
        }

        p {
          color: #a8a8a8;
          font-size: 14px;
          line-height: 20px;

          @media only screen and (max-width : 480px) {
            display: none;
          }
        }
      }
    }
    .box:nth-child(1),
    .box:nth-child(5),
    .box:nth-child(9),
    .box:nth-child(13),
    .box:nth-child(17) {
      div {
        border-left: 1px solid #F0F0F0;
      }
    }

    @media only screen and (max-width : 480px) {
      .box:nth-child(1),
      .box:nth-child(5),
      .box:nth-child(9),
      .box:nth-child(13),
      .box:nth-child(17) {
        div {
          border-left: none;
        }
      }
      .box:nth-child(odd) {
        div {
          border-left: 1px solid #F0F0F0;
        }
      }
    }
  }
`;

// Clientes
export const Clientes = styled.section`
  h2 {
    text-align: center;
    margin-bottom: 7.5px;
    margin-top: 0;

    @media only screen and (max-width : 480px) {
      padding: 0 30px;
    }
  }
  p {
    text-align: center;
    color: #00844E;
    font-size: 18px;
  }

  .grid {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-gap: 50px 20px;
    padding-top: 70px;

    @media only screen and (max-width : 480px) {
      grid-template-columns: 100%;
      grid-gap: 30px;
      padding-top: 30px;
    }

    picture {
      border: 1px solid #C9C9C9;
      height: 170px;
      width: 100%;
      padding: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      
      img {
        width: auto;
        height: 100%;
        filter: grayscale(100%);
        transition: all ease-in-out .45s;
      }
    }
    picture:hover {
      img {
        filter: grayscale(0);
      }
    }
  }
`;

// Depoimentos
export const Depoimentos = styled.section`
  padding-top: 180px;

  @media only screen and (max-width : 480px) {
    padding-top: 110px;
  }
  
  h2 {
    margin: 0 0 70px 0;
    text-align: center;
    position: relative;

    @media only screen and (max-width : 480px) {
      margin: 0;
    }

    &:after {
      content: url(${IconeDepoimentos});
      z-index: -1;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) scale(.75);
      top: -55px;
    }
  }

  .wrapper {
    position: relative;
    max-width: 900px;
    padding-bottom: 200px;

    @media only screen and (max-width : 480px) { 
      width: 90%;
      padding-bottom: 100px;
    }

    &:after {
      content: '';
      position: absolute;
      height: 300px;
      background: #F2F2F2;
      width: 300%;
      margin-left: -100%;
      top: 50px;
      z-index: -1;

      @media only screen and (max-width : 480px) {
        top: 80px;
        display: none;
      }
    }

    .content {
      display: grid;

      @media only screen and (max-width : 480px) {
        grid-template-columns: 95%;
        margin-left: 5%;
        grid-gap: 30px;
      }

      picture {
        img {
          width: 100%;
          height: 395px;
          object-fit: cover;
          object-position: center;

          @media only screen and (max-width : 480px) {
            height: 330px;
          }
        }
      }
      > div {
        padding-left: 50px;
        padding-top: 50px;
        font-style: italic;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media only screen and (max-width : 480px) {
          padding-left: 0;
          text-align: center;
        }
        
        p {
          color: #747474;
          font-weight: 500;
          line-height: 28px;
          font-size: 20px;
          
          @media only screen and (max-width : 480px) {
            font-size: 18px;
            line-height: 26px;
          }
        }

        h4 {
          color: #00844E;
          margin-bottom: 0;
          font-size: 18px;

          @media only screen and (max-width : 480px) {
            font-size: 16px;
          }
        }
      }
    }
    .content.foto {
      grid-template-columns: 390px 1fr;
    }
    .content.texto {
      grid-template-columns: 100%;
      padding: 70px 20% 0 20%;
    }
  }

  .slick-prev:before, .slick-next:before {
    color: #000;
    font-size: 50px;
    opacity: .3;
  }
  .slick-prev, .slick-next {
    height: 60px;
    width: 60px;
  }
  .slick-next {
    right: -85px;
  }
  .slick-prev {
    left: -90px;
  }
`;

// CallToAction
export const CallToAction = styled.section`
  background: #00844E;
  height: auto;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px 0 100px 0;

  @media only screen and (max-width : 480px) {
    > div {
      width: 85%;
      margin: 0 auto;
    }
  }

  h3 {
    font-size: 40px;
    font-weight: 900;
    line-height: 46px;
    color: #fff;
    letter-spacing: 1px;

    @media only screen and (max-width : 480px) {
      font-size: 36px;
      line-height: 40px;
    }
  }
  p {
    color: #fff;
    width: 400px;
    margin: 0 auto;
    font-size: 16px;
    margin-top: 0;

    @media only screen and (max-width : 480px) {
      width: 100%;
    }
  }

  form {
    display: flex;
    margin: 30px auto 0 auto;
    width: 420px;

    @media only screen and (max-width : 480px) {
      width: 100%;
    }

    input {
      height: 54px;
      line-height: 54px;
      color: #fff;
      background: none;
      border: 1px solid #fff;
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
      text-indent: 20px;
      font-size: 16px;
      border-right: none;
      width: 100%;
      transition: all ease-in-out .3s;
    }
    input::placeholder {
      color: #fff;
    }
    input:focus {
      background: #fff;
      color: #00844E;
    }
    button {
      height: 54px;
      line-height: 54px;
      width: 145px;
      color: #fff;
      background: #01D07B;
      border: 1px solid #fff;
      border-left: none;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      font-weight: bold;
      cursor: pointer;
      transition: all ease-in-out .3s;
    }
    button:hover {
      background: darkgreen;
    }
  }

  button {
    height: 54px;
    line-height: 54px;
    padding: 0 25px;
    width: auto;
    color: darkgreen;
    background: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: all ease-in-out .3s;
    margin-top: 20px;
    border: none;
    border-radius: 10px;
  }
  button:hover {
    background: #01D07B;
    color: #fff;
  }
`;

// Blog
export const Blog = styled.section`
  background: #FFF6E8;
  padding-bottom: 125px;

  @media only screen and (max-width : 480px) {
    padding-bottom: 100px;
  }

  .topo {
    background: url(${TopoBlog});
    background-position: center;
    background-size: cover;
    height: 530px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 55px;

    @media only screen and (max-width : 480px) {
      height: 400px;
      padding-bottom: 30px;
    }

    h2 {
      color: #fff;
      padding-top: 210px;
    }

    p {
      color: #fff;
      font-size: 18px;
      width: 390px;

      @media only screen and (max-width : 480px) {
        width: 85%;
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 130px;
    grid-gap: 22px;

    @media only screen and (max-width : 480px) {
      padding-top: 50px;
      grid-template-columns: 100%;
      grid-gap: 30px;
    }

    .box {
      background: #fff;
      padding: 20px;
      border-radius: 10px;
      transition: all ease-in-out .4s;
      cursor: pointer;

      .thumb {
        position: relative;
        height: 200px;

        picture {

          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
          }
        }
        span {
          position: absolute;
          display: block;
          background: #fff;
          height: 50px;
          line-height: 50px;
          font-weight: bold;
          color: #6E6E6E;
          text-transform: uppercase;
          font-size: 17px;
          width: 100px;
          text-align: center;
          bottom: 0;
          left: 0;

          transition: all ease-in-out .4s;
        }
      }
      h4 {
        color: #454545;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        margin-top: 30px;
        font-weight: 900;
        letter-spacing: 1px;
        height: 66px;
        overflow-y: hidden;
      }
      p {
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 0;
        color: #454545;
        height: 145px;
      }
      button {
        width: 145px;
        height: 35px;
        padding-left: 12.5px;
        color: #C68F3F;
        font-weight: 500;
        border: none;
        background: none;
        border-bottom: 4px solid #C68F3F;
        margin-top: 25px;
        margin-bottom: 5px;
        text-align: left;
        cursor: pointer;
        transition: all ease-in-out .4s;

      }
    }
    .box:hover {
      background: #C68F3F;

      .thumb {
        span {
          background: #C68F3F;
          color: #fff;
        }
      }

      button {
        color: #fff;
        border-color: #fff;
      }
    }
  }

  .todas {
    width: 185px;
    background: #fff;
    border: 1px solid #C68F3F;
    color: #C68F3F;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;
    margin: 70px auto 0 auto;
    display: block;
    transition: all ease-in .3s;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      background: #C68F3F;
      color: #fff;
    }
  }
`;

// Ranking
export const Ranking = styled.section`
  position: relative;
  padding-bottom: 160px;

  @media only screen and (max-width : 480px) {
    padding-bottom: 90px;
  }

  &:after {
    content: '';
    position: absolute;
    height: 600px;
    background: url(${Raking_bg});
    width: 100%;
    background-size: cover;
    z-index: -1;
    top: 0;
  }

  .wrapper {
    position: relative;

    h2 {
      color: #fff;
      text-align: center;
      padding-top: 135px;
      width: 650px;
      margin: 0 auto;
      text-transform: inherit;
      line-height: 50px;
      margin-bottom: 105px;

      @media only screen and (max-width : 480px) {
        padding-top: 80px;
        width: 100%;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 60px;
      }
    }

    .tabela {
      background: #fff;
      border-radius: 20px;
      box-shadow: 1px 1px 20px 10px rgba(0,0,0,0.04);
      border: 1px solid #F0F0F0;
      padding: 70px 90px;
      overflow-y: scroll;

      @media only screen and (max-width : 480px) {
        padding: 50px 40px;
      }
      

      h4 {
        font-size: 22px;
        font-weight: bold;
        color: #959595;
        text-align: center;
        margin-bottom: 60px;

        @media only screen and (max-width : 480px) {
          font-size: 20px;
          margin-bottom: 0;
        }
      }
    }

    .topo {
      display: none;
    }

    .corpo {
      @media only screen and (max-width : 480px) {
        width: 600px;
      }
    }

    .linha {
      display: grid;
      grid-template-columns: repeat(5,20%);
      align-content: center;

      div {
        text-align: center;
        height: 45px;
        line-height: 45px;

        img {
          height: 75px;
        }

        h5 {
          font-weight: bold;
          color: #7B7B7B;
        }

        p {

        }
      }
    }

    .linha.cabecalho {
      background: #EFF2F9 !important;
    }
    .linha.icones {
      height: 160px;
    }
    .linha:nth-child(even) {background: #F5F5F5}
    .linha:nth-child(odd) {background: #FFF}
  }
`;


