import React from 'react';

// Assets 
import MarcaBranca from '../../assets/img/marca_branca.svg';
import Heart from '../../assets/img/heart.svg';

// Icons
import { FaFacebookF, FaInstagram  } from "react-icons/fa";

// Styles
import { Rodape } from './styles';

export default function Footer() {
  return (
    <>

      <Rodape>
        <div className="infos">
          <img src={MarcaBranca} />
          <span className="divisor"></span>
          <div>
            <h3>Venha nos visitar</h3>
            <p><small>42</small> 9 9158-9338</p>
            <address>
              <p>Rua Octávio de Carvalho, 1078 Ap 14, Ponta Grossa, PR, 84015-500</p>
            </address>
            <div className="social">
              <a target="__blank" href="https://www.facebook.com/consipa/">
                <FaFacebookF color="#0A4D32" size="26" />
              </a>
              <a target="__blank" href="https://www.instagram.com/consipa_consultoria/">
                <FaInstagram color="#0A4D32" size="26" />
              </a>
            </div>
          </div>
        </div>

        <div className="creditos">
          <p>©2019 CONSIPA todos os direitos reservados.</p>
          <p>
            Feito com <img src={Heart} /> por <a href="https://agenciaade.com.br">agenciaade.com.br</a>
          </p>
        </div>
      </Rodape>
    </>
  );
}
