import styled from 'styled-components';

// Container
export const Container = styled.section`
  position: relative;
  padding-bottom: 100px;
  padding-top: 70px;
  background: #ffd89d;

  .content {
    width: 100%;
    background: #fff;
    border-radius: 25px;
    box-shadow: 5px 10px 30px 1px rgba(0,0,0,0.055);
    padding: 45px 50px;

    .titulozinho {
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #f2f3f5;

      h2 {
        color: #DE6D33;
        font-weight: bold;
        margin: 8px 0 0 15px;
        font-size: 16px;
      }
    }

    .tabela {
      .topo {
        h3 {
          color: #7a7a7a;
          font-weight: bold;
          font-size: 16px;
          text-align: center;
          line-height: 60px;
          margin: 0;
        }
      }

      .corpo {
        width: 80%;
        margin: auto;

        @media only screen and (max-width : 480px) {
          width: 600px;
        }
      }
      
      .linha {
        display: grid;
        grid-template-columns: 50% 50%;
        align-content: center;
        border-radius: 5px;

        div {
          text-align: center;
          height: 45px;
          line-height: 45px;

          img {
            height: 75px;
          }

          h5 {
            font-weight: bold;
            color: #7B7B7B;
          }

          p {
            font-weight: bold;
          }
        }
        .comprovante {
          a {
            text-decoration: underline;
            font-style: italic;
            cursor: pointer;
            color: #10764f;
          }
        }
      }

      .linha.cabecalho {
        background: #EFF2F9 !important;
      }
      .linha.icones {
        height: 160px;
      }
      .linha:nth-child(even) {background: #FFF}
      .linha:nth-child(odd) {background: #F5F5F5}
    }
  }
`;

// Outros
export const Outros = styled.section`
  padding-top: 90px;

  h3 {
    color: #DE6D33;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;

    li {
      list-style: none;
      border-radius: 15px;
      display: flex;
      align-items: center;
      padding: 35px 30px;
      height: auto;
      width: 100%;
      transition: all ease .4s;
      background: #fff;

      p {
        color: #DE6D33;
        font-weight: bold;
        margin: 0 0 0 25px;
      }
    }
    li:hover {
      background: #fef6e9
    }
  }
`;