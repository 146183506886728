import React from 'react';
import { NavHashLink as NavLink } from 'react-router-hash-link';

// Utils
import { smoothScroll } from '../../utils/smoothScroll';

// Icons
import { FaFacebookF, FaInstagram  } from "react-icons/fa";

// Styles
import { Container, Actions } from './styles';


export default function Header() {

  // Functions
  function scrollTo(element) {
    smoothScroll(element);
  }

  return (
    <>  
      <Container>
        <div className="wrapper">
          <a href="/">
            <h1>
              <img src={require('../../assets/img/marca_consipa.svg')} height="85" />
            </h1>
          </a>

          <nav>
            {/* <a onClick={() => scrollTo('#banner')}>
              <li className="active">Home</li>
            </a> */}
            <NavLink to="/#banner" smooth>
              <li className="active">Home</li>
            </NavLink>
            {/* <a onClick={() => scrollTo('#a-empresa')}>
              <li>A Empresa</li>
            </a> */}
            <NavLink to="/#a-empresa" smooth>
              <li>A Empresa</li> 
            </NavLink>
            {/* <a onClick={() => scrollTo('#servicos')}>
              <li>Serviços</li>
            </a>  */}
            <NavLink to="/#servicos" smooth>
              <li>Serviços</li> 
            </NavLink>
            {/* <a onClick={() => scrollTo('#equipe')}>
              <li>Equipe</li>  
            </a>  */}
            <NavLink to="/#equipe" smooth>
              <li>Equipe</li> 
            </NavLink>
            {/* <a onClick={() => scrollTo('#clientes')}>
              <li>Clientes</li>  
            </a>   */}
            <NavLink to="/#clientes" smooth>
              <li>Clientes</li> 
            </NavLink>
            <NavLink to="/#blog" smooth>
              <li>Blog</li> 
            </NavLink>
            {/* <a onClick={() => scrollTo('#ranking')}>
              <li>Ranking</li>
            </a> */}
            {/* <a onClick={() => scrollTo('#contato')}>
              <li>Contato</li>
            </a> */}
            <NavLink to="/#contato" smooth>
              <li>Contato</li> 
            </NavLink>
          </nav>

          <Actions>
            <div>
              <a href="/login">
                <button className="area">Área do cliente</button>
              </a>
              <a target="__blank" href="https://www.facebook.com/consipa/">
                <span>
                  <FaFacebookF color="white" />
                </span>
              </a>
              <a target="__blank" href="https://www.instagram.com/consipa_consultoria/">
                <span>
                  <FaInstagram color="white" />
                </span>
              </a>
            </div>
            <a href="/consultoria-online-gratis">
              <button className="consultoria">Avaliação <strong>grátis</strong></button>
            </a>
          </Actions>
        </div>
      </Container>
    </>
  );
}
