import styled from 'styled-components';

// Container
export const Container = styled.header`
  height: 95px;

  img {
    padding-top: 10px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 95px;

    p {
      margin: 0;
      color: #DE6D33;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }

    button {
      background: #707070;
      font-size: 13px;
      font-weight: bold;
      padding: 10px 20px;
      font-weight: 400;
      transition: all ease-in .45s;
      border-radius: 50px;
      border: none;
      cursor: pointer;
      color: #fff;

      &:hover {
        background: red;
      }

      span {
        text-transform: uppercase;
        font-weight: 300;
        display: inline-block;
        padding-right: 6px;
      }
    }
  }
`;