import styled from 'styled-components';

// Container
export const Container = styled.header`
  height: 95px;
  position: fixed;
  z-index: 999;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid rgba(0,121,52, .2);

  img {
    padding-top: 10px;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 95px;

    nav {
      width: 600px;
      display: flex;
      justify-content: space-between;
      margin-left: 20px;
      margin-top: 15px;

      @media only screen and (max-width : 480px) {
        display: none;
      }

      li {
        list-style: none;
        text-transform: uppercase;
        color: #9A9A9A;
        font-weight: bold;
        font-size: 14px;
        transition: all ease-out .25s;
        letter-spacing: -.25px;
      }
      li.active,
      li:hover {
        color: #E74E16;
      }
    }
  }
`;

// Actions
export const Actions = styled.div`
  button {
    cursor: pointer;
    border: none;
    border-radius: 50px;
    color: #fff;
    
    &.area {
      background: #e4b034;
      font-size: 11px;
      font-weight: bold;
      padding: 3px 10px;
    }
    &.consultoria {
      background: #DE6D32;
      font-size: 14px;
      font-weight: bold;
      padding: 7px 23px;
      font-weight: 400;
      margin-top: 7.5px;
      margin-bottom: 5px;
      transition: all ease-in .45s;

      &:hover {
        background: #a03a04;
      }

      strong {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  > div {
    display: flex;
    justify-content: flex-end;
    padding-right: 5px;

    span {
      background: green;
      border-radius: 50%;
      height: 17.5px;
      width: 17.5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: 5px;
      cursor: pointer;

      svg {
        height: 10px;
        padding-left: 3px;
      }
    }
  }
`;
