import styled from 'styled-components';

// Topo
export const Topo = styled.figure`
  width: 100%;
  height: 500px;
  position: relative;
  margin-top: 95px;
  
  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
`;

// Container
export const Container = styled.section`
  max-width: 720px !important;
  font-family: 'Lora', serif;
  padding-top: 50px;

  @media only screen and (max-width : 480px) {
    max-width: 90%;
    width: 90%;
    margin: 0 auto;
  }
    
  > h1 {
    font-size: 40px;
    line-height: 48px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 5px;

    @media only screen and (max-width : 480px) {
      font-size: 36px;
      line-height: 44px;
    }
  }

  > span {
    display: block;
    margin-bottom: 30px;
    font-size: 17px;
    font-style: italic;
  }

  > div {
    p {
      font-size: 22px;
      line-height: 40px;

      @media only screen and (max-width : 480px) {
        font-size: 20px;
        line-height: 38px;
      }
    }

    ul {
      padding-left: 20px;

      li {
        font-size: 22px;
        line-height: 40px;
      }
    }
  }  
`;

// Share
export const Share = styled.div`
  max-width: 720px !important;
  padding-top: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  z-index: 95;
  padding-bottom: 100px;

  @media only screen and (max-width : 480px) {
    max-width: 90%;
    width: 90%;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      display: block;
      font-size: 18px;
      margin-bottom: 8px;
      font-weight: bold;
    }
  }

  button {
    display: block;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 40px;
    background: #0b4d32;
    border: none;
    color: #fff;
    -webkit-transition: all ease-in-out .4s;
    transition: all ease-in-out .4s;
    font-weight: bold;
    cursor: pointer;
    width: 190px;

    @media only screen and (max-width : 480px) {
      margin: 0 auto;
    }
  }
  button:hover {
    background: #de6c33;
  }

`;
