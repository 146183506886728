import { createGlobalStyle } from 'styled-components';

// And Design
import 'antd/es/style/index.css';
import 'antd/lib/notification/style/index.css';

// Slick carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Guttenberg
import '../assets/css/guttenberg.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700,900&display=swap');

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box !important;
    outline: 0;
  }

  *:focus {
    outline: 0;
  }

  html {
    font-family: 'Raleway', sans-serif;
  }

  body {
    background-color: unset; /* REMOVER */
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
    overflow-x: hidden;
    font-family: 'Raleway', sans-serif;
  }

  #root {
    overflow-x: hidden;
  }

  strong {
    font-weight: 900;
  }

  .wrapper {
    max-width: 1020px;
    margin: 0 auto;

    @media only screen and (max-width : 480px) {
      max-width: 90%;
    }
  }

  .titulo {
    font-weight: 900;
    font-size: 38px;
    color: #00844E;
    text-transform: uppercase;

    @media only screen and (max-width : 480px) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  .hr {
    width: 470px;
    border: 1px solid #00844E;
    margin: 0 auto;

    @media only screen and (max-width : 480px) {
      width: 90%;
    }
  }

  main.fixed {
    margin-top: 95px;
  }
`;
