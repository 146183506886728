import styled from 'styled-components';

import Raking_bg from '../../../assets/img/raking_bg.jpg';

// Container
export const Container = styled.section`
  position: relative;
  padding-bottom: 160px;
  padding-top: 70px;

  @media only screen and (max-width : 480px) {
    padding-bottom: 0;

    .wrapper {
      max-width: 100%;
    }
  }

  &:after {
    content: '';
    position: absolute;
    height: 380px;
    background: url(${Raking_bg});
    width: 100%;
    background-size: cover;
    z-index: -1;
    top: 0;
  }

  h2 {
    font-weight: 900;
    text-align: center;
    margin-top: 0;
    color: #fff;
    line-height: 46px;
    font-size: 42px;

    @media only screen and (max-width : 480px) {
      line-height: 40px;
      font-size: 36px;
      padding: 0 10%;
    }
  }

  img {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    width: 105%;
    margin-left: -5%;

    @media only screen and (max-width : 480px) {
      margin-left: -10%;
      width: 110%;
    }
  }

  > div {
    display: grid;
    grid-template-columns: 42% 58%;
    align-items: center;
    margin-top: 60px;

    @media only screen and (max-width : 480px) {
      grid-template-columns: 100%;
    }
  }
`;


// Form
export const Form = styled.form`
  width: 100%;
  background: #fff;
  border-radius: 15px;
  box-shadow: 5px 10px 30px 1px rgba(0,0,0,0.055);
  border: 1px solid rgba(240,240,240,1);
  padding: 30px 85px 55px 85px;

  @media only screen and (max-width : 480px) {
    max-width: 100%;
    padding: 50px 10% 80px 10%;
    border-radius: 0;
  }

  .label {
    font-size: 20px;
    font-weight: bold;
    color: #098151;
    padding-bottom: 10px;
    margin-top: 30px;
    letter-spacing: 1px;
    display: block;

    @media only screen and (max-width : 480px) {
      font-size: 16px;
    }
  }

  input {
    background: #f4f4f4;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    text-indent: 20px;
    border: none;
    border: 1px solid #f4f4f4;
    transition: all ease-in .3s;
  }
  input:focus {
    border-color: #098151;
  }

  button {
    width: 130px;
    height: 52px;
    color: #fff;
    text-align: center;
    line-height: 52px;
    background: #01D07B;
    margin: 45px auto 0 auto;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: block;

    &:hover {
      background: #0A4D32;
      transition: all ease-in .3s
    }
  }

  .error {
    width: 100%;
    display: block;
    margin: 20px 0 0 0;
    padding: 10px 0;
    background: red;
    text-align: center;
    color: #fff;
    border-radius: 6px;
  }
`;