import styled from 'styled-components';

import Raking_bg from '../../assets/img/raking_bg.jpg';

// Container
export const Container = styled.section`
  position: relative;
  padding-bottom: 100px;
  padding-top: 70px;

  @media only screen and (max-width : 480px) {
    padding-bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    background: url(${Raking_bg});
    width: 100%;
    background-size: cover;
    z-index: -1;
    top: 0;
  }

  .wrapper {
    display: grid;
    grid-template-columns: 42% 50%;
    align-items: flex-start;
    margin-top: 30px;
    grid-gap: 8%;

    @media only screen and (max-width : 480px) {
      grid-template-columns: 100%;
      /* max-width: 100%; */
    }

    h2 {
      font-weight: bold;
      text-align: center;
      margin-top: 0;
      color: #DE6D33;
      font-size: 42px;
      text-transform: uppercase;
      margin-bottom: 0;
      margin-top: 100px;

      @media only screen and (max-width : 480px) {
        line-height: 40px;
        font-size: 36px;
        padding: 0 10%;
      }
    }
    h3 {
      text-align: center;
      color: orange;
      font-size: 30px;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;
    }

    .lista {
      width: 100%;
      background: #fff;
      border-radius: 25px;
      box-shadow: 5px 10px 30px 1px rgba(0,0,0,0.055);
      border: 1px solid rgba(240,240,240,1);
      padding: 45px 50px;

      @media only screen and (max-width : 480px) {
        max-width: 100%;
        padding: 50px 10% 80px 10%;
        border-radius: 0;
      }

      li {
        list-style: none;
        border-radius: 15px;
        display: flex;
        align-items: center;
        padding: 35px 30px;
        height: auto;
        width: 100%;
        transition: all ease .4s;

        p {
          color: #DE6D33;
          font-weight: bold;
          margin: 0 0 0 25px;
        }
      }
      li:hover {
        background: #fef6e9
      }
    }
  }
`;