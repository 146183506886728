import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from 'react-input-mask';
import { notification } from 'antd';

// Services
import ademail from '../../services/ademail';

// Assets 
import MarcaBranca from '../../assets/img/marca_branca.svg';
import Heart from '../../assets/img/heart.svg';
import imgContato from '../../assets/img/contato.jpg';

// Icons
import { FaFacebookF, FaInstagram  } from "react-icons/fa";

// Styles
import { Container, Contato, Rodape } from './styles';

export default function FooterContato() {

  const formik = useFormik({
    initialValues: {
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
    },

    validationSchema: Yup.object({
      nome: Yup.string().required('Campo nome é obrigatório'),
      email: Yup.string()
        .email('Informe um e-mail válido')
        .required('Campo e-mail é obrigatório'),
      telefone: Yup.string().required('Campo telefone é obrigatório'),
      mensagem: Yup.string().required('Campo mensagem é obrigatório'),
    }),

    onSubmit: async (values, { resetForm }) => {
      const body = `
        <p>Nome: ${values.nome}</p>
        <p>E-mail: ${values.email}</p>
        <p>Telefone: ${values.telefone}</p>
        <p>Mensagem: ${values.mensagem}</p>
      `;

      const mail = {
        to: 'lmartinspg@gmail.com',
        from: values.email,
        as: values.nome,
        // bcc: JSON.stringify(['web@agenciaade.com.br']),
        subject: 'Novo contato via site - Consipa',
        message: body,
      };

      try {
        // Sucesso ao enviar
        await ademail.post('/email', mail);

        notification.success({
          message: 'Contato enviado com sucesso! 🚀',
          placement: 'bottomLeft',
        });

        resetForm();
      } catch (error) {
        // Erro ao enviar

        notification.error({
          message: 'Não foi possivel enviar o contato! 😔',
          description: 'Verfique os campos e tente novamente mais tarde...',
          placement: 'bottomLeft',
        });

        console.log(error);
      }
    },
  });

  return (
    <>
      <Container id="contato" className="wrapper">
        <h2 className="titulo">Contato</h2>
        <p>Um de nossos consultores irá te responder</p>

        <Contato>
          <img src={imgContato} alt=""/>
          <form onSubmit={formik.handleSubmit}>
            <h3>Envie sua mensagem:</h3>
            <div>
              <label>Nome:</label>
              <input
                type="text"
                name="nome"
                placeholder="Nome"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.nome}
              />
              {formik.touched.nome && formik.errors.nome ? (
                <span>{formik.errors.nome}</span>
              ) : null}
            </div>

            <div>
              <label>Email:</label>
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <span>{formik.errors.email}</span>
              ) : null}
            </div>

            <div>
              <label>Telefone:</label>
              <Input
                type="tel"
                name="telefone"
                mask="(99) 99999-9999"
                maskChar={null}
                placeholder="Telefone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.telefone}
              />
              {formik.touched.telefone && formik.errors.telefone ? (
                <span>{formik.errors.telefone}</span>
              ) : null}
            </div>

            <div>
              <label>Mensagem:</label>
              <textarea
                name="mensagem"
                cols="30"
                rows="8"
                placeholder="Mensagem"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mensagem}
              />
              {formik.touched.mensagem && formik.errors.mensagem ? (
                <span>{formik.errors.mensagem}</span>
              ) : null}
            </div>

            <button>Enviar</button>
          </form>
        </Contato>
      </Container>

      <Rodape>
        <div className="infos">
          <img src={MarcaBranca} />
          <span className="divisor"></span>
          <div>
            <h3>Venha nos visitar</h3>
            <p><small>42</small> 9 9158-9338</p>
            <address>
              <p>Rua Octávio de Carvalho, 1078 Ap 14, Ponta Grossa, PR, 84015-500</p>
            </address>
            <div className="social">
              <a target="__blank" href="https://www.facebook.com/consipa/">
                <FaFacebookF color="#0A4D32" size="26" />
              </a>
              <a target="__blank" href="https://www.instagram.com/consipa_consultoria/">
                <FaInstagram color="#0A4D32" size="26" />
              </a>
            </div>
          </div>
        </div>

        <div className="creditos">
          <p>©2019 CONSIPA todos os direitos reservados.</p>
          <p>
            Feito com <img src={Heart} /> por <a href="https://agenciaade.com.br">agenciaade.com.br</a>
          </p>
        </div>
      </Rodape>
    </>
  );
}
