import styled from 'styled-components';

import Raking_bg from '../../assets/img/raking_bg.jpg';

// Container
export const Container = styled.section`
  position: relative;
  padding-bottom: 160px;
  padding-top: 145px;

  @media only screen and (max-width : 480px) {
    padding-bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    height: 550px;
    background: url(${Raking_bg});
    width: 100%;
    background-size: cover;
    z-index: -1;
    top: 0;
  }

  p {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin-bottom: 0;

    @media only screen and (max-width : 480px) {
      font-size: 20px;
      padding: 0 10%;
    }
  }

  h2 {
    font-weight: 900;
    text-align: center;
    margin-top: 0;
    color: #fff;
    line-height: 46px;
    font-size: 42px;

    @media only screen and (max-width : 480px) {
      line-height: 40px;
      font-size: 36px;
      padding: 0 10%;
    }
  }
`;


// Form
export const Form = styled.form`
  max-width: 700px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 5px 10px 30px 1px rgba(0,0,0,0.055);
  border: 1px solid rgba(240,240,240,1);
  margin: 70px auto 0 auto;
  padding: 50px 85px 85px 85px;

  @media only screen and (max-width : 480px) {
    max-width: 100%;
    padding: 50px 10% 80px 10%;
    border-radius: 0;
  }

  .label {
    font-size: 20px;
    font-weight: bold;
    color: #098151;
    padding-bottom: 10px;
    margin-top: 30px;
    letter-spacing: 1px;
    display: block;

    @media only screen and (max-width : 480px) {
      font-size: 16px;
    }
  }

  input {
    background: #f4f4f4;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    text-indent: 20px;
    border: none;
  }
  textarea {
    background: #f4f4f4;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    border: none;
    resize: none;
  }

  .bloco {
    background: #f4f4f4;
    width: 100%;
    height: 60px;
    border-radius: 5px;
    padding: 0 0 0 20px;
    display: flex;
    align-items: center;

    label {
      font-size: 15px;
      font-weight: bold;
      color: #6c6c6c;

      @media only screen and (max-width : 480px) {
        font-size: 14px;
      }
    }

    input {
      border-bottom: 2px solid #6c6c6c;
      margin: 0 12.5px;
      background: none;
      border-radius: 0;
      height: 25px;
      text-indent: 4px;
    }

    span {
      font-size: 15px;
      font-weight: bold;
      color: #6c6c6c;
    }
  }
  .bloco.tamanho {
    input {
       width: 85px;
    }
  }
  .bloco.atividade {
    input {
      width: 200px;

      @media only screen and (max-width : 480px) {
        display: none;
      }
    }
    .outros {
      padding-left: 13px;
      position: relative;
      z-index: 91;

      @media only screen and (max-width : 480px) {
        display: none;
      }
    }
  }

  .localizacao {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 30px;

    @media only screen and (max-width : 480px) {
      grid-template-columns: 100%;
    }
  }

  button {
    width: 130px;
    height: 52px;
    color: #fff;
    text-align: center;
    line-height: 52px;
    background: #01D07B;
    margin: 45px auto 0 auto;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    display: block;

    &:hover {
      background: #0A4D32;
      transition: all ease-in .3s
    }
  }
`;