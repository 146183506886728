import React, { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";
import { NavHashLink as NavLink } from 'react-router-hash-link';
// import ProgressiveImage from 'react-progressive-image';

// Components 
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

// Services
import api from '../../../services/api';

// Styles
import { Topo, Container, Share } from './styles';

export default function Post( {match} ) {

  // States
  const [dados, setDados] = useState(null);
  
  // useEffect para retornar os dados
  useEffect(() => {
  
    // Função com a requisição assincrona
    const getDados = async () => {

      // Pega todos os parametros da url
      const { params } = match;

      // Faz a requisição com o slug
      const { data } = await api.get(`/blog/${params.slug}`);
      
      // Seta o estado
      setDados(data);
    };

    // Chama a função
    getDados();
  }, []);
  
  return (
    <>
      <Helmet>
        <title>Consipa | Blog</title>
      </Helmet>

      <Header />

      <main>
        <Topo>
          <img src={dados ? dados.imagem : ''} />
          {/* <ProgressiveImage src={dados ? dados.imagem : ''} placeholder={dados ? dados.imagem : ''} delay={3000}>
            {src => <img src={src} alt='teste' />}
          </ProgressiveImage> */}
        </Topo>

        <Container key={!!dados && dados.ID} className="wrapper single">
          <h1>{!!dados && dados.titulo}</h1>
          <span>{!!dados && dados.data}</span>

          <div>
            {!!dados && ReactHtmlParser(dados.conteudo)}
          </div>
        </Container>

        <Share>
          <NavLink
          to="/#blog"
          activeClassName="selected">
            <button>Voltar</button>
          </NavLink>
          {/* <div>
            <span>Compartilhe:</span>
            <div className="sharethis-inline-share-buttons"></div>
          </div> */}
        </Share>
      </main>

      <Footer />
    </>
  );
}
