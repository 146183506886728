import React from 'react';
import { Helmet } from 'react-helmet';

// Components 
import HeaderRestrita from '../../components/HeaderRestrita';
import Footer from '../../components/Footer';

// Styles
import { Container } from './styles';

export default function AreaRestrita() {

  return (
    <>
      <Helmet>
        <title>Consipa | Área restrita</title>
      </Helmet>

      <HeaderRestrita />

      <main>
        <Container>
          <div className="wrapper">
            <div>
              <h2>Seja bem-vindo(a)</h2>
              <h3>{localStorage.getItem('nome')}</h3>
            </div>
            
            <ul className="lista">
              <a href="/area-restrita/agenda-dos-consultores">
                <li>
                  <img height="65" src={require('../../assets/img/arearestrita/Icone_Agenda.svg')} />
                  <p>Agenda dos consultores</p>
                </li>
                </a>

                <a href="/area-restrita/historico-de-pagamentos">
                  <li>
                    <img height="65" src={require('../../assets/img/arearestrita/Icone_Pagamentos.svg')} />
                    <p>Histórico de pagamentos</p>
                  </li>
                </a>

                <a href="/area-restrita/historico-de-consultorias">
                  <li>
                    <img height="65" src={require('../../assets/img/arearestrita/Icone_Visitas.svg')} />
                    <p>Histórico de consultorias</p>
                  </li>
                </a>
                <a href="/area-restrita/consultoria-remota">
                  <li>
                    <img height="65" src={require('../../assets/img/arearestrita/Icone_Remote.svg')} />
                    <p>OnSipa (Consultoria remota)</p>
                  </li>
                </a>
            </ul>
          </div>
        </Container>
      </main>

      <Footer />
    </>
  );
}
