import styled, { keyframes } from 'styled-components';

const heartBeat = keyframes`
  0%, 100%, 40%, 60% {
      transform: scale(1);
  }
  20%, 80% {
      transform: scale(1.3);
  }
`;

// Container
export const Container = styled.section`
  padding-top: 130px;

  @media only screen and (max-width : 480px) {
    padding-top: 80px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 0;

    @media only screen and (max-width : 480px) {
      margin-bottom: 10px;
    }
  }
  p {
    text-align: center;
    color: #00844E;
    font-weight: bold;
    font-size: 20px;

    @media only screen and (max-width : 480px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
`;

// Contato
export const Contato = styled.div`
  margin-top: 80px;
  background: #DE6D33;
  padding: 110px 70px 110px 0;
  display: grid;
  grid-template-columns: 40% 60%;
  align-items: center;
  z-index: 8;
  position: relative;

  @media only screen and (max-width : 480px) {
    margin-top: 40px;
    margin-left: -6%;
    width: 112%;
    grid-template-columns: 100%;
    padding: 70px 7.5%;
  }

  img {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 535px;
    object-fit: cover;
    object-position: 80% center;
    margin-left: -120px;
    width: calc(100% + 130px);

    @media only screen and (max-width : 480px) {
      display: none;
    }
  }

  form {
    padding: 80px 70px;
    background: #fff;
    border-radius: 15px;

    @media only screen and (max-width : 480px) {
      padding: 40px 30px;
    }

    h3 {
      color: #00844E;
      font-weight: bold;
      font-size: 20px;
    }

    label {
      font-weight: 500;
      color: #00844E;
      padding-left: 10px;
    }
    input {
      height: 50px;
    }
    input, textarea { 
      width: 100%;
      border: 1px solid #00844E;
      border-radius: 12px;
      text-indent: 20px;
      margin: 3px 0 10px 0;
      color: #fff;
      transition: all ease-in .3s
    }
    textarea {
      padding: 20px;
      text-indent: 0;
    }
    input:focus, textarea:focus {
      background: #00844E;
    }
    button {
      background: #01D07B;
      color: #fff;
      margin: 20px 0 0 auto;
      border: none;
      padding: 14px 45px;
      display: block;
      border-radius: 10px;
      cursor: pointer;

      &:hover {
        background: #0A4D32;
        transition: all ease-in .3s
      }
    }
  }
`;

// Rodape
export const Rodape = styled.footer`
  background: #0A4D32;
  padding-top: 500px;
  margin-top: -500px;
  z-index: 1;
  position: relative;

  @media only screen and (max-width : 480px) {
    padding-top: 0;
    margin-top: 0;
  }

  .infos {
    width: 670px;
    margin: 120px auto 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width : 480px) {
      margin: 0 auto;
      width: 100%;
      padding: 65px 0 40px 0;
      flex-direction: column;
    }

    .divisor {
      display: block;
      background: rgba(255,255,255,.5);
      width: 1px;
      height: 240px;

      @media only screen and (max-width : 480px) {
        width: 80%;
        height: 1px;
        margin: 45px 0 35px 0;
      }
    }

    div {
      width: 265px;
      color: #fff;

      h3 {
        font-size: 31px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 5px;

        @media only screen and (max-width : 480px) {
          font-size: 28px;
        }
      }
      > p {
        font-size: 18px;
      }
      .social {
        display: flex;

        a {
          height: 40px;
          width: 40px;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 15px;
          border-radius: 50%;
          cursor: pointer;
        }
      }
    }

    img {
      height: 160px;

      @media only screen and (max-width : 480px) {
        height: 110px;
      }
    }
  }

  .creditos {
    width: 600px;
    height: 95px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width : 480px) {
      width: 90%;
      height: auto;
      flex-direction: column;
    }

    p {
      margin: 0;
      color: #fff;
      font-weight: bold;
      font-size: 14px;

      @media only screen and (max-width : 480px) {
        font-size: 13px;
        padding-bottom: 15px;

        &:last-of-type {
          padding-bottom: 20px;
        }
      }

      img {
        display: inline-block;
        margin: 0 4px;
        animation-name: ${heartBeat};
        animation-iteration-count: infinite;
        animation-duration: 2s;

      }

      a {
        color: #fff;
      }
    }
  }
`;
