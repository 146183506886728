import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactHtmlParser from 'react-html-parser';
import { Table, Modal } from 'antd';

// Icons
import { AiOutlineBarcode } from 'react-icons/ai';
import { MdFileDownload } from 'react-icons/md';

// Services
import api from '../../../services/api';

// Components 
import HeaderRestrita from '../../../components/HeaderRestrita';
import Footer from '../../../components/Footer';

// Styles
import { Container, Outros } from './styles';
import 'antd/lib/table/style/css';
import 'antd/lib/modal/style/css';

export default function HistoricoPagamentos() {

  // States
  const [selectedModal, setSelectedModal] = useState({
    modalisopen: false,
    selectmodal: '',
  });
  const [pagamentos, setPagamentos] = useState(null);

  // Variáveis localStorage
  const id = localStorage.getItem('id');
  const tipo = localStorage.getItem('tipo');

  // Colunas da tabela
  const colunas = [
    {
      title: 'Código',
      dataIndex: 'codigo',
      width: '15%',
    },
    {
      title: 'Data',
      dataIndex: 'data',
      width: '15%',
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      width: '15%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '15%',
    },
    {
      title: <AiOutlineBarcode />,
      dataIndex: 'boleto',
      key: 'boleto',
      width: '20%',
      render: (text, record) => (
        <span>
          <a href={record.boleto} target="_blank" rel="noopener noreferrer">
            Baixar boleto <MdFileDownload />
          </a>
        </span>
      ),
    },
    {
      title: <AiOutlineBarcode />,
      dataIndex: 'comprovante',
      key: 'comprovante',
      width: '20%',
      render: (text, record) => (
        <span>
          <a href={record.comprovante.url} target="_blank" rel="noopener noreferrer">
            Baixar comprovante <MdFileDownload />
          </a>
        </span>
      ),
    },

  ];

  // useEffect
  useEffect( () => {

    async function getPagamentos() {
      await api.get(`/pagamentos?id=${id}`).then(response => {
        setPagamentos(response.data);
      });
    }
    getPagamentos(); 
  }, []);

  const data =
  !!pagamentos && pagamentos.map(dado => ({
    key: dado.ID,
    codigo: dado.ID,
    data: dado.data,
    valor: dado.valor,
    status: dado.status,
    boleto: dado.boleto,
    comprovante: dado.comprovante,
  }));

  return (
    <>
      <Helmet>
        <title>Consipa | Histórico de pagamentos</title>
      </Helmet>

      <HeaderRestrita />

      <main>
        <Container>
          <div className="wrapper content">
            <div className="titulozinho">
              <img height="33" src={require('../../../assets/img/arearestrita/Icone_Pagamentos.svg')} />
              <h2>Histórico de pagamentos</h2>
            </div>

            <Table
            bordered={false}
            columns={colunas}
            dataSource={data} />
          </div>

          <Outros className="wrapper">
            <h3>Consulte outros históricos</h3>
            <ul>
              <a href="/area-restrita/agenda-dos-consultores">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Agenda.svg')} />
                  <p>Agenda dos consultores</p>
                </li>
              </a>

              <a href="/area-restrita/historico-de-consultorias">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Visitas.svg')} />
                  <p>Histórico de consultorias</p>
                </li>
              </a>

              <a href="/area-restrita/consultoria-remota">
                <li>
                  <img height="65" src={require('../../../assets/img/arearestrita/Icone_Remote.svg')} />
                  <p>Consultoria remota</p>
                </li>
              </a>
            </ul>
          </Outros>
        </Container>
      </main>

      <Footer />
    </>
  );
}
