import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import ReactHtmlParser from 'react-html-parser';
import { Link } from "react-router-dom";

// API
import api from '../../services/api';

// Components 
import Header from '../../components/Header';
import FooterContato from '../../components/FooterContato';

// Icons
import { FaWhatsapp } from "react-icons/fa";

import imgQuem_somos from '../../assets/img/quem_somos.jpg';
import imgOque from '../../assets/img/oque.jpg';

import TopoEquipe from '../../assets/img/equipe_topo.png';
import ImgEquipe1 from '../../assets/img/equipe_1.jpg';
import ImgEquipe2 from '../../assets/img/equipe_2.jpg';
import ImgEquipe3 from '../../assets/img/equipe_3.jpg';

import Cliente_alto_alegre from '../../assets/img/clientes/cliente_alto_alegre.jpeg';
import Cliente_cresol from '../../assets/img/clientes/cliente_cresol.jpeg';
import Cliente_guarapuava from '../../assets/img/clientes/cliente_guarapuava.jpeg';
import Cliente_ipiranga from '../../assets/img/clientes/cliente_ipiranga.jpeg';
import Cliente_nita from '../../assets/img/clientes/cliente_nita.jpeg';

import iconeServicos1 from '../../assets/img/servicos/servico_1.svg';
import iconeServicos2 from '../../assets/img/servicos/servico_2.svg';
import iconeServicos3 from '../../assets/img/servicos/servico_3.svg';
import iconeServicos4 from '../../assets/img/servicos/servico_4.svg';
import iconeServicosLeite from '../../assets/img/servicos/leite.svg';

import imgBlog1 from '../../assets/img/blog/blog_1.jpg';
import imgBlog2 from '../../assets/img/blog/blog_2.jpg';
import imgBlog3 from '../../assets/img/blog/blog_3.jpg';
import imgBlog4 from '../../assets/img/blog/blog_4.jpg';
import imgBlog5 from '../../assets/img/blog/blog_5.jpg';
import imgBlog6 from '../../assets/img/blog/blog_6.jpg';

// Styles
import { Banner, BemVindo, Sobre, Servicos, Equipe, Clientes, Depoimentos, CallToAction, Blog, Ranking } from './styles';

export default function Home( {match} ) {

  // States
  const [banners, setBanners] = useState(null);
  const [dadosHome, setDadosHome] = useState(null);

  // useEffect
  useEffect(() => {
    
    // Função com a requisição assincrona
    const getDadosBanner = async () => {
      const { data } = await api.get('/banners');

      // Setar no estado
      setBanners(data.banners);
    };

    // Função com a requisição assincrona
    const getDadosHome = async () => {
      const { data } = await api.get('/home');

      // Setar no estado
      setDadosHome(data);
      
      console.log(data);
    };

    // Chama a função
    getDadosBanner();
    getDadosHome();

    console.log(match);
    
  }, []);

  // Slider banner
  const config_slider_banner = {
    dots: false,
    infinite: true,
    speed: 1300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  // Slider depoimentos
  const config_slider_depoimentos = {
    dots: false,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  return (
    <>
      <Helmet>
        <title>Consipa | Home</title>
      </Helmet>

      <Header />

      <main className="fixed">
        <Banner id="banner">
          <Slider {...config_slider_banner}>
            {!!banners && banners.map( (banner, index) => (
              <div key={index}>
                <div className="slide">
                  <div className="content">
                    <h3>{banner.titulo}</h3>
                    <p>{banner.subtitulo}</p>
                    {banner.possui_botao && (
                      <a href={banner.link_do_botao}>
                        <button>{banner.texto_do_botao}</button>
                      </a>
                    )}
                  </div>
                  <picture>
                    <img src={banner.imagem.url} />
                  </picture>
                </div>
              </div>
            ))}
          </Slider>
        </Banner>

        <BemVindo>
          <h2>Seja bem-vindo à Consipa</h2>
        </BemVindo>

        <Sobre id="a-empresa">
          <div className="wrapper">
            <div className="quemSomos">
              <div>
                <h2 className="titulo">Quem somos?</h2>
                <hr />
                <h3>Atuamos aplicando tecnologias de vanguarda para transformar as famílias envolvidas.</h3>
                {!!dadosHome && (
                  ReactHtmlParser(dadosHome.sobre.quem_somos)
                )}

                <a href="https://api.whatsapp.com/send?phone=5542991589338&text=Ol%C3%A1%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20" target="__blank">
                  <div className="info">
                    <FaWhatsapp color="#00844E" size="55" />
                    <div>
                      <h4>Fale com um consultor</h4>
                      <strong>42 9 9158-9338</strong>
                    </div>
                  </div>
                </a>
              </div>

              <picture>
                <img src={imgQuem_somos} />
              </picture>
            </div>

            <hr className="hr" />

            <div className="oqueSao">
              <picture>
                <img src={imgOque} />
              </picture>
              <h2 className="titulo">O que são os SIPA</h2>
              <div>
                {!!dadosHome && (
                  ReactHtmlParser(dadosHome.sobre.o_que_sao_os_sipa)
                )}
              </div>
            </div>

            <div className="importancia">
              <h2 className="titulo">A importância dos sipa no brasil</h2>
              <div>
                {!!dadosHome && (
                  ReactHtmlParser(dadosHome.sobre.a_importancia_dos_sipa_no_brasil)
                )}
              </div>
            </div>
          </div>
        </Sobre>

        <Servicos id="servicos">
          <div className="topo">
            <h2 className="titulo">Conheça nossos serviços</h2>
            <p>Temos soluções para que sua empresa produza como nunca, e renda de forma otimizada e eficaz.</p>
          </div>

          <div className="grid wrapper">
            {!!dadosHome && dadosHome.servicos.map( ( servico, index) => (
              <div id={index}>
                <h3>{servico.servico_titulo}</h3>
                <p>
                  {ReactHtmlParser(servico.servico_descricao)}
                </p>
              </div>
            ))}

            {/* <div>
              <img src={iconeServicos2} height="86" />
              <h3>Pecuária 4k</h3>
              <p>
                {!!dadosHome && (
                  ReactHtmlParser(dadosHome.servicos.pecuaria_4k)
                )}
              </p>
            </div>

            <div>
              <img src={iconeServicosLeite} height="88" />
              <h3>Gerenciamento programa Leite Saudável</h3>
              <p>
                {!!dadosHome && (
                  ReactHtmlParser(dadosHome.servicos.gerenciamento_programa_leite_saudavel)
                )}
              </p>
            </div>

            <div>
              <img src={iconeServicos4} height="81" />
              <h3>Consultorias em grupo</h3>
              <p>
                {!!dadosHome && (
                  ReactHtmlParser(dadosHome.servicos.consultorias_em_grupo)
                )}
              </p>
            </div>

            <div>
              <img src={iconeServicos1} height="82" />
              <h3>Curadoria do conhecimento</h3>
              <p>
                {!!dadosHome && (
                  ReactHtmlParser(dadosHome.servicos.curadoria_do_conhecimento)
                )}
              </p>
            </div>

            <div>
              <img src={iconeServicos3} height="80" />
              <h3>Nutrição de precisão</h3>
              <p>
                {!!dadosHome && (
                  ReactHtmlParser(dadosHome.servicos.nutricao_de_precisao)
                )}
              </p>
            </div> */}
          </div>

          <hr className="hr" />
        </Servicos>

        <Equipe id="equipe">
          <div className="topo">
            <h2 className="titulo">Uma equipe à sua disposição</h2>
            <p>Profissionais especializados e prontos para oferecer o melhor.</p>
          </div>

          <div className="grid wrapper">
            {!!dadosHome && dadosHome.equipe.map( ( integrante, index) => (
              <div className="box" key={index}>
                <picture>
                  <img src={integrante.foto ? integrante.foto.url : ''}></img>
                </picture>
                <div>
                  <h3>{integrante.nome}</h3>
                  <h4>{integrante.funcao}</h4>
                  <p>{integrante.descricao}</p>
                </div>
              </div>
            ))}
          </div>

          <hr className="hr" />
        </Equipe>
        
        <Clientes id="clientes">
          <h2 className="titulo">Clientes e parceiros</h2>
          <p>Veja quem são os nossos clientes</p>

          <div className="grid wrapper">
            {!!dadosHome && dadosHome.clientes.map( ( logo, index) => (
              <picture key={index}>
                <img src={logo.url} />
              </picture>
            ))}
          </div>
        </Clientes>

        <Depoimentos id="depoimentos">
          <h2 className="titulo">Depoimentos</h2>
          <div className="wrapper">
            <Slider {...config_slider_depoimentos}>
              {dadosHome && dadosHome.depoimentos.map( (depoimento, index) => 
                  <div key={index}>
                    {depoimento.tipo == 'video' ? (
                      <div className="content video">
                        <picture>
                          <img src={ImgEquipe1} />
                        </picture>
                        <div>
                          <p>{depoimento.depoimento}</p>
                          <h4>{depoimento.nome}</h4>
                        </div>
                      </div>
                    ) : ''}

                    {depoimento.tipo == 'foto' ? (
                      <div className="content foto">
                        <picture>
                          <img src={ImgEquipe1} />
                        </picture>
                        <div>
                          <p>{depoimento.depoimento}</p>
                          <h4>{depoimento.nome}</h4>
                        </div>
                      </div>
                    ) : ''}


                    {depoimento.tipo == 'texto' ? (
                      <div className="content texto">
                        <div>
                          <p>{depoimento.depoimento}</p>
                          <h4>{depoimento.nome}</h4>
                        </div>
                      </div>
                    ) : ''}
                  </div>
                )}
            </Slider>
          </div>
        </Depoimentos>

        <CallToAction id="callToAction">
          <div>
            <h3>Oi, possui alguma<br/>dúvida?</h3>
            <p>Converse com um de nossos consultores a respeito.</p>
            {/* <form>
              <input placeholder="Digite seu email"></input>
              <button>Cadastrar</button>
            </form> */}
            <a href="https://api.whatsapp.com/send?phone=5542991589338&text=Ol%C3%A1%20vim%20atrav%C3%A9s%20do%20site%20e%20gostaria%20de%20" target="__blank">
              <button>Falar com consultor</button>
            </a>
          </div>
        </CallToAction>

        <Blog>
          <div className="topo">
            <h2 id="blog" className="titulo">Blog</h2>
            <p>Notícias e avaliação do mercado agropecuário brasileiro e internacional</p>
          </div>

          <div className="grid wrapper">
            {!!dadosHome && dadosHome.blog.map( (post, index) => (
              <a href={`/blog/${post.slug}`} key={index}>
                <div className="box">
                  <div className="thumb">
                    <span>{post.data}</span>
                    <picture>
                      <img src={post.imagem ? post.imagem : ''} alt={post.titulo}></img>
                    </picture>
                  </div>
                  <h4>{post.titulo}</h4>
                  <p>{ReactHtmlParser(post.resumo)}</p>
                  <button>Leia mais</button>
                </div>
              </a>
            ))}
          </div>

          {/* <button className="todas">Veja todas as notícias</button> */}
        </Blog>
      </main>

      <FooterContato />
    </>
  );
}
